<template>
  <div class="overview-container">
    <div class="graph-header">
      <slot></slot>
    </div>
    <div class="graph-headings">
      <h2 class="graph-h2">机能数据对比报告</h2>
      <h3 class="graph-h3">Comparative Report on Functional Data</h3>
    </div>
    <div class="graph-body">
      <Row>
        <Col span="8" class="padding10">
          <table class="data-table">
            <tr>
              <th>时间</th>
              <th>肺活量</th>
            </tr>
            <tr v-for="row in data" :key="row.time">
              <template v-if="row.time !== '对比'">
                <td>{{row.time}}</td>
                <td :class="tdColor(row.key1.level)">{{row.key1.value}}</td>
              </template>
              <template v-else>
                <td>{{row.time}}</td>
                <td>{{row.key1.value | compareResult}}</td>
              </template>
            </tr>
          </table>
        </Col>
        <Col span="8" class="padding10">
           <table class="data-table">
            <tr>
              <th>时间</th>
              <th>握力左</th>
              <th>握力右</th>
            </tr>
            <tr v-for="row in data" :key="row.time">
              <template v-if="row.time !== '对比'">
                <td>{{row.time}}</td>
                <td :class="tdColor(row.key2.level)">{{row.key2.value}}</td>
                <td :class="tdColor(row.key3.level)">{{row.key3.value}}</td>
              </template>
              <template v-else>
                <td>{{row.time}}</td>
                <td>{{row.key2.value | compareResult}}</td>
                <td>{{row.key3.value | compareResult}}</td>
              </template>
            </tr>
          </table>
        </Col>
        <Col span="8" class="padding10">
          <table class="data-table">
            <tr>
              <th>时间</th>
              <th>左平衡</th>
              <th>右平衡</th>
            </tr>
            <tr v-for="row in data" :key="row.time">
              <template v-if="row.time !== '对比'">
                <td>{{row.time}}</td>
                <td :class="tdColor(row.key4.level)">{{row.key4.value}}</td>
                <td :class="tdColor(row.key5.level)">{{row.key5.value}}</td>
              </template>
              <template v-else>
                <td>{{row.time}}</td>
                <td>{{row.key4.value | compareResult}}</td>
                <td>{{row.key5.value | compareResult}}</td>
              </template>
            </tr>
          </table>
        </Col>
      </Row>
      <Row class="mb20">
        <Col span="12">
          <div class="graph-chart"
             ref="chartFHL"></div>
          <h3 class="assessment-heading">监测结果分析：</h3>
          <p class="assessment-content">{{energy.capacity_str}}</p>
        </Col>
        <Col span="12">
          <div class="graph-chart"
             ref="chartWL"></div>
          <h3 class="assessment-heading">监测结果分析：</h3>
          <p class="assessment-content">{{energy.grip_str}}</p>
        </Col>
      </Row>
      <div class="graph-chart"
             ref="chartBalance"></div>
      <h3 class="assessment-heading">监测结果分析：</h3>
      <p class="assessment-content">{{energy.balanced_str}}</p>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
import echarts from 'echarts';

export default {
	props: {
		energy: {
			type: Object,
		},
	},
	data() {
		return {};
	},
	computed: {
		data() {
			return this.energy.table;
		},
	},
	filters: {
		compareResult(val) {
			if (val === 0) {
				return '持平';
			} else if (val === 1) {
				return '升高';
			} else if (val === -1) {
				return '降低';
			}
		},
	},
	mounted() {
		const commonSingleOtp = {
			title: {
				text: '肺活量',
				textStyle: {
					fontWeight: 'bold',
					fontSize: 18,
				},
				left: 'center',
			},
			grid: {
				y: 40,
			},
			tooltip: {
				trigger: 'axis',
			},
			dataset: {
				dimensions: ['time', 'data'],
				source: this.energy.chart_capacity,
			},
			xAxis: {
				type: 'category',
				axisPointer: {
					type: 'shadow',
				},
			},
			yAxis: {
				min: function (value) {
					return value.min > 2 ? value.min - 2 : 0;
				},
			},
			series: [
				{
					type: 'bar',
					itemStyle: {
						normal: {
							color: function (params) {
								var colorList = ['#5a9bd5', '#ed7d31', '#a5a5a5', '#0aaf9f', '#e89589'];
								return colorList[params.dataIndex];
							},
						},
					},
				},
			],
		};

		const monthLength = this.energy.compare_month.length;
		function commonBarOpt(title, source) {
			const series = [];
			for (let i = 0; i < monthLength; i++) {
				series.push({ type: 'bar' });
			}
			const obj = {
				title: {
					text: title,
					textStyle: {
						fontWeight: 'bold',
						fontSize: 18,
					},
					left: 'center',
				},
				legend: {
					bottom: 0,
				},
				grid: {
					y: 40,
				},
				tooltip: {
					trigger: 'axis',
				},
				dataset: {
					source: source,
				},
				color: ['#5a9bd5', '#ed7d31', '#a5a5a5'],
				xAxis: {
					type: 'category',
					axisPointer: {
						type: 'shadow',
					},
				},
				yAxis: {
					min: function (value) {
						return value.min > 2 ? Math.ceil(value.min - 2) : 0;
					},
				},
				series: series,
			};
			return obj;
		}
		echarts.init(this.$refs.chartFHL).setOption(commonSingleOtp);
		echarts.init(this.$refs.chartWL).setOption(commonBarOpt('握力', this.energy.chart_grip));
		echarts
			.init(this.$refs.chartBalance)
			.setOption(commonBarOpt('平衡', this.energy.chart_balanced));
	},
	methods: {
		tdColor(val) {
			if (val === 1) {
				return 'td-red';
			} else if (val === 0) {
				return 'td-green';
			} else if (val === -1) {
				return 'td-yellow';
			}
		},
	},
};
</script>

<style lang="less">
.padding10 {
  padding: 0 10px;
}

.mb20 {
  margin-bottom: 20px;
}
</style>
