<template>
  <div class="overview-container">
    <div class="graph-header">
      <slot></slot>
    </div>
    <div class="graph-headings" style="margin-bottom:0;">
      <h2 class="graph-h2">血压数据对比报告</h2>
      <h3 class="graph-h3">Comparative Report on Blood Pressure Data</h3>
    </div>
    <div class="graph-body">
      <Row>
        <Col span="6"><div class="graph-chart" ref="chartUpload"></div></Col>
        <Col span="6"><div class="graph-chart" ref="chartError"></div></Col>
        <Col span="12"><div class="graph-chart" ref="chartDaily"></div></Col>
      </Row>
      <Row>
        <Col span="6"><div class="graph-chart" ref="chartSS"></div></Col>
        <Col span="6"><div class="graph-chart" ref="chartSZ"></div></Col>
        <Col span="6"><div class="graph-chart" ref="chartMY"></div></Col>
        <Col span="6"><div class="graph-chart" ref="chartXL"></div></Col>
      </Row>
      <Row>
        <Col span="12"><div class="graph-chart" ref="chartJX"></div></Col>
        <Col span="12"><div class="graph-chart" ref="chartWJ"></div></Col>
      </Row>
    </div>
    <div class="graph-body">
      <h3 class="assessment-heading">监测结果分析：</h3>
      <p class="assessment-title">{{blood.str1}}</p>
      <p class="assessment-content">{{blood.str2}}</p>
      <hr class="divide-line--black">
      <p class="assessment-title">{{blood.str3}}</p>
      <p class="assessment-content">{{blood.str4}}</p>
      <hr class="divide-line--black">
      <p class="assessment-title">{{blood.str5}}</p>
      <p class="assessment-content">{{blood.str6}}</p>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
import echarts from 'echarts';

export default {
	props: {
		blood: {
			type: Object,
		},
	},
	data() {
		return {};
	},
	mounted() {
		function commonSingleOpt(title, xData, data, name = '次数') {
			const obj = {
				title: {
					text: title,
					textStyle: {
						fontWeight: 'bold',
						fontSize: 18,
					},
					left: 'center',
					bottom: 0,
				},
				tooltip: {
					trigger: 'axis',
				},
				xAxis: [
					{
						type: 'category',
						data: xData,
						axisPointer: {
							type: 'shadow',
						},
					},
				],
				grid: {
					x: 40,
				},
				yAxis: [
					{
						type: 'value',
						name: name,
						min: function (value) {
							return value.min > 2 ? value.min - 2 : 0;
						},
					},
				],
				series: [
					{
						name: name,
						type: 'bar',
						data: data,
						itemStyle: {
							normal: {
								color: function (params) {
									var colorList = ['#5a9bd5', '#ed7d31', '#a5a5a5', '#0aaf9f', '#e89589'];
									return colorList[params.dataIndex];
								},
							},
						},
					},
				],
			};
			return obj;
		}
		const monthLength = this.blood.compare_month.length;
		function commonMultiOpt(title, source, name = '次数') {
			const series = [];
			for (let i = 0; i < monthLength; i++) {
				series.push({ type: 'bar' });
			}
			const obj = {
				title: {
					text: title,
					textStyle: {
						fontWeight: 'bold',
						fontSize: 18,
					},
					left: 'center',
					bottom: 0,
				},
				tooltip: {
					trigger: 'axis',
				},
				dataset: {
					source: source,
				},
				color: ['#5a9bd5', '#ed7d31', '#a5a5a5'],
				xAxis: {
					type: 'category',
					axisPointer: {
						type: 'shadow',
					},
				},
				yAxis: {
					name: name,
				},
				series: series,
			};
			return obj;
		}

		function commonLineOpt(title, source) {
			const series = [];
			for (let i = 0; i < monthLength; i++) {
				series.push({ type: 'line' });
			}
			const obj = {
				title: {
					text: title,
					textStyle: {
						fontWeight: 'bold',
						fontSize: 18,
					},
					left: 'center',
					top: 20,
				},
				legend: {
					bottom: 0,
				},
				tooltip: {
					trigger: 'axis',
				},
				dataset: {
					source: source,
				},
				color: ['#5a9bd5', '#ed7d31', '#a5a5a5'],
				xAxis: {
					type: 'category',
					axisPointer: {
						type: 'shadow',
					},
				},
				yAxis: {
					scale: true,
				},
				series: series,
			};
			return obj;
		}
		echarts
			.init(this.$refs.chartUpload)
			.setOption(
				commonSingleOpt('上传次数对比', this.blood.compare_month, this.blood.upload_count),
			);
		echarts
			.init(this.$refs.chartError)
			.setOption(
				commonSingleOpt(
					'异常数据占比对比',
					this.blood.compare_month,
					this.blood.abnormal_percent,
					'百分比',
				),
			);
		echarts
			.init(this.$refs.chartDaily)
			.setOption(commonMultiOpt('每日测量分布', this.blood.time_compare));
		echarts
			.init(this.$refs.chartSS)
			.setOption(
				commonSingleOpt('收缩压平均值', this.blood.compare_month, this.blood.systolic, '平均值'),
			);
		echarts
			.init(this.$refs.chartSZ)
			.setOption(
				commonSingleOpt('舒张压平均值', this.blood.compare_month, this.blood.diastolic, '平均值'),
			);
		echarts
			.init(this.$refs.chartMY)
			.setOption(
				commonSingleOpt('脉压差平均值', this.blood.compare_month, this.blood.myc, '平均值'),
			);
		echarts
			.init(this.$refs.chartXL)
			.setOption(
				commonSingleOpt('心率平均值', this.blood.compare_month, this.blood.heart, '平均值'),
			);
		echarts
			.init(this.$refs.chartJX)
			.setOption(commonLineOpt('月度静息血压汇总', this.blood.other1));
		echarts
			.init(this.$refs.chartWJ)
			.setOption(commonLineOpt('月度卧警血压汇总', this.blood.other2));
	},
	methods: {},
};
</script>

<style lang="less">
</style>
