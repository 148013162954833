<template>
  <div class="overview-container">
    <div class="graph-header">
      <slot></slot>
    </div>
    <div class="graph-headings">
      <h2 class="graph-h2">数据对比分析总览</h2>
      <h3 class="graph-h3">Overview of Data Analysis</h3>
    </div>
    <div class="graph-body">
      <table class="data-table">
        <tr>
          <th>项目</th>
          <th v-for="v in data[0].data" :key="v.time">{{v.time}}</th>
          <th>改变</th>
          <th>评价</th>
        </tr>
        <tr v-for="row in data" :key="row.time">
            <td>{{row.name}}</td>
            <td v-for="t in row.data" :key="t.timestamp">{{t.data}}</td>
            <td>
              <Icon type="md-arrow-up" v-if="row.change[0] === 1"></Icon>
              <Icon type="md-remove" v-if="row.change[0] === 0"></Icon>
              <Icon type="md-arrow-round-down" v-if="row.change[0] === -1"></Icon>
              <template v-if="row.change.length > 1">
              <Icon type="md-arrow-up" v-if="row.change[1] === 1"></Icon>
              <Icon type="md-remove" v-if="row.change[1] === 0"></Icon>
              <Icon type="md-arrow-round-down" v-if="row.change[1] === -1"></Icon>
              </template>
            </td>
            <td>{{row.level | formatLevel}}</td>
        </tr>
      </table>
      <hr class="divide-line">
      <h3 class="graph-h3-green">本月数据上传情况：</h3>
      <Row class="row-item">
        <Col span="8">
          <span class="item-label">血压：</span>
          <span class="item-content">{{overview.this_month.key1.count}}条</span>
          <Icon color="#717e88"
                size="18"
                :type="overview.this_month.key1.add === -1 ? 'md-arrow-round-down' : (overview.this_month.key1.add === 0 ? 'md-arrow-round-forward' : 'md-arrow-round-up')"></Icon>
        </Col>
        <Col span="8">
          <span class="item-label">血糖：</span>
          <span class="item-content">{{overview.this_month.key2.count}}条</span>
          <Icon color="#717e88"
                size="18"
                :type="overview.this_month.key2.add === -1 ? 'md-arrow-round-down' : (overview.this_month.key2.add === 0 ? 'md-arrow-round-forward' : 'md-arrow-round-up')"></Icon>
        </Col>
        <Col span="8">
          <span class="item-label">运动达标：</span>
          <span class="item-content">{{overview.this_month.key3.count}}天</span>
          <Icon color="#717e88"
                size="18"
                :type="overview.this_month.key3.add === -1 ? 'md-arrow-round-down' : (overview.this_month.key3.add === 0 ? 'md-arrow-round-forward' : 'md-arrow-round-up')"></Icon>
        </Col>
      </Row>
      <Row class="row-item">
        <Col span="8">
          <span class="item-label">平衡：</span>
          <span class="item-content">{{overview.this_month.key4}}</span>
        </Col>
        <Col span="8">
          <span class="item-label">肺活量：</span>
          <span class="item-content">{{overview.this_month.key5}}</span>
        </Col>
        <Col span="8">
          <span class="item-label">握力：</span>
          <span class="item-content">{{overview.this_month.key6}}</span>
        </Col>
      </Row>
      <Row class="row-item">
        <Col span="8">
          <span class="item-label">腰围：</span>
          <span class="item-content">{{overview.this_month.key7}}</span>
        </Col>
        <Col span="8">
          <span class="item-label">人体成分：</span>
          <span class="item-content">{{overview.this_month.key8}}</span>
        </Col>
      </Row>
      <hr class="divide-line">
      <h3 class="graph-h3-green">综合评价：</h3>
      <p class="assessment">{{overview.this_month.total}}</p>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
export default {
	props: {
		overview: {
			type: Object,
		},
	},
	data() {
		return {};
	},
	filters: {
		formatLevel(val) {
			if (val === 0) {
				return '标准';
			} else if (val === 1) {
				return '偏高';
			} else if (val === -1) {
				return '偏低';
			} else if (val === -2) {
				return '—';
			}
		},
	},
	computed: {
		data() {
			return this.overview.overview;
		},
	},
	methods: {},
};
</script>

<style lang="less" scoped>
.graph-h3-green {
  font-size: 18px;
  color: #65cd98;
  margin-bottom: 15px;
}

.row-item {
  margin: 25px 0;
  font-size: 18px;
  font-weight: 700;
  .item-label {
    width: 120px;
    text-align: right;
    display: inline-block;
  }
  .item-content {
    color: #4370c1;
  }
}

.assessment {
  text-indent: 2em;
  font-size: 18px;
}
</style>
