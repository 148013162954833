<template>
  <div class="overview-container">
    <div class="graph-header">
      <slot></slot>
    </div>
    <div class="graph-headings">
      <h2 class="graph-h2">人体成分数据对比报告</h2>
      <h3 class="graph-h3">Comparative Report on Body Composition Data</h3>
    </div>
    <div class="graph-body">
      <table class="data-table">
        <tr>
          <th>时间</th>
          <th>体重</th>
          <th>体格指数</th>
          <th>体脂肪率</th>
          <th>内脂指数</th>
          <th>体水分率</th>
          <th>骨骼肌率</th>
          <th>骨矿含量</th>
          <th>基础代谢</th>
        </tr>
        <tr v-for="row in data" :key="row.time">
          <template v-if="row.time !== '对比'">
            <td>{{row.time}}</td>
            <td :class="tdColor(row.key1.level)">{{row.key1.value}}</td>
            <td :class="tdColor(row.key2.level)">{{row.key2.value}}</td>
            <td :class="tdColor(row.key3.level)">{{row.key3.value}}</td>
            <td :class="tdColor(row.key4.level)">{{row.key4.value}}</td>
            <td :class="tdColor(row.key5.level)">{{row.key5.value}}</td>
            <td :class="tdColor(row.key6.level)">{{row.key6.value}}</td>
            <td :class="tdColor(row.key7.level)">{{row.key7.value}}</td>
            <td :class="tdColor(row.key8.level)">{{row.key8.value}}</td>
          </template>
          <template v-else>
            <td>{{row.time}}</td>
            <td class="td-blue">
              <Icon type="md-arrow-up" v-if="row.key1.value === 1"></Icon>
              <Icon type="md-remove" v-if="row.key1.value=== 0"></Icon>
              <Icon type="md-arrow-round-down" v-if="row.key1.value === -1"></Icon>
            </td>
            <td class="td-blue">
              <Icon type="md-arrow-up" v-if="row.key2.value === 1"></Icon>
              <Icon type="md-remove" v-if="row.key2.value=== 0"></Icon>
              <Icon type="md-arrow-round-down" v-if="row.key2.value === -1"></Icon>
            </td>
            <td class="td-blue">
              <Icon type="md-arrow-up" v-if="row.key3.value === 1"></Icon>
              <Icon type="md-remove" v-if="row.key3.value=== 0"></Icon>
              <Icon type="md-arrow-round-down" v-if="row.key3.value === -1"></Icon>
            </td>
            <td class="td-blue">
              <Icon type="md-arrow-up" v-if="row.key4.value === 1"></Icon>
              <Icon type="md-remove" v-if="row.key4.value=== 0"></Icon>
              <Icon type="md-arrow-round-down" v-if="row.key4.value === -1"></Icon>
            </td>
            <td class="td-blue">
              <Icon type="md-arrow-up" v-if="row.key5.value === 1"></Icon>
              <Icon type="md-remove" v-if="row.key5.value=== 0"></Icon>
              <Icon type="md-arrow-round-down" v-if="row.key5.value === -1"></Icon>
            </td>
            <td class="td-blue">
              <Icon type="md-arrow-up" v-if="row.key6.value === 1"></Icon>
              <Icon type="md-remove" v-if="row.key6.value=== 0"></Icon>
              <Icon type="md-arrow-round-down" v-if="row.key6.value === -1"></Icon>
            </td>
            <td class="td-blue">
              <Icon type="md-arrow-up" v-if="row.key7.value === 1"></Icon>
              <Icon type="md-remove" v-if="row.key7.value=== 0"></Icon>
              <Icon type="md-arrow-round-down" v-if="row.key7.value === -1"></Icon>
            </td>
            <td class="td-blue">
              <Icon type="md-arrow-up" v-if="row.key8.value === 1"></Icon>
              <Icon type="md-remove" v-if="row.key8.value=== 0"></Icon>
              <Icon type="md-arrow-round-down" v-if="row.key8.value === -1"></Icon>
            </td>
          </template>
        </tr>
      </table>
      <Row>
        <Col span="6">
        <div class="graph-chart"
             ref="chartTZ"></div>
        </Col>
        <Col span="6">
        <div class="graph-chart"
             ref="chartTG"></div>
        </Col>
        <Col span="6">
        <div class="graph-chart"
             ref="chartTZF"></div>
        </Col>
        <Col span="6">
        <div class="graph-chart"
             ref="chartNZF"></div>
        </Col>
      </Row>
      <Row>
        <Col span="6">
        <div class="graph-chart"
             ref="chartTSF"></div>
        </Col>
        <Col span="6">
        <div class="graph-chart"
             ref="chartGG"></div>
        </Col>
        <Col span="6">
        <div class="graph-chart"
             ref="chartGK"></div>
        </Col>
        <Col span="6">
        <div class="graph-chart"
             ref="chartJC"></div>
        </Col>
      </Row>
    </div>
    <div class="graph-body">
      <h3 class="assessment-heading">监测结果分析：</h3>
      <p class="assessment-label">现在状态：</p>
      <p class="assessment-content-right">{{body.strs.str1 || '无'}}</p>
      <p class="assessment-label">状态评价：</p>
      <p class="assessment-content-right">{{body.strs.str2 || '无'}}</p>
      <hr class="divide-line--black">
      <p class="assessment-label">安全建议：</p>
      <p class="assessment-content-right">{{body.strs.str3 || '无'}}</p>
      <hr class="divide-line--black">
      <p class="assessment-label">对比变化：</p>
      <p class="assessment-content-right">{{body.strs.str4 || '无'}}</p>
      <p class="assessment-label">对比描述：</p>
      <p class="assessment-content-right">{{body.strs.str5 || '无'}}</p>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import echarts from 'echarts';

export default {
	props: {
		body: {
			type: Object,
		},
	},
	data() {
		return {};
	},
	computed: {
		data() {
			return this.body.table;
		},
	},
	mounted() {
		function commonBarOpt(title, source) {
			const obj = {
				title: {
					text: title,
					textStyle: {
						fontWeight: 'bold',
						fontSize: 18,
					},
					left: 'center',
				},
				grid: {
					x: 40,
					y: 40,
				},
				tooltip: {
					trigger: 'axis',
				},
				dataset: {
					source: source,
					dimensions: ['time', 'data'],
				},
				xAxis: {
					type: 'category',
					axisPointer: {
						type: 'shadow',
					},
				},
				yAxis: {
					min: function (value) {
						return value.min > 2 ? value.min - 2 : 0;
					},
				},
				series: [
					{
						type: 'bar',
						itemStyle: {
							normal: {
								color: function (params) {
									var colorList = ['#5a9bd5', '#ed7d31', '#a5a5a5', '#0aaf9f', '#e89589'];
									return colorList[params.dataIndex];
								},
							},
						},
					},
				],
			};
			return obj;
		}

		echarts
			.init(this.$refs.chartTZ)
			.setOption(commonBarOpt('体重', this.body.chart.statics_weight));
		echarts
			.init(this.$refs.chartTG)
			.setOption(commonBarOpt('体格指数', this.body.chart.statics_build));
		echarts
			.init(this.$refs.chartTZF)
			.setOption(commonBarOpt('体脂肪率', this.body.chart.statics_body_fat));
		echarts
			.init(this.$refs.chartNZF)
			.setOption(commonBarOpt('内脂指数', this.body.chart.statics_lactone));
		echarts
			.init(this.$refs.chartTSF)
			.setOption(commonBarOpt('体水分率', this.body.chart.statics_body_water));
		echarts
			.init(this.$refs.chartGG)
			.setOption(commonBarOpt('骨骼肌率', this.body.chart.statics_musculi_skeleti));
		echarts
			.init(this.$refs.chartGK)
			.setOption(commonBarOpt('骨矿含量', this.body.chart.statics_bmc));
		echarts
			.init(this.$refs.chartJC)
			.setOption(commonBarOpt('基础代谢', this.body.chart.statics_basic));
	},
	methods: {
		tdColor(val) {
			if (val === 1) {
				return 'td-red';
			} else if (val === 0) {
				return 'td-green';
			} else if (val === -1) {
				return 'td-yellow';
			}
		},
	},
};
</script>

<style lang="less">
.graph-body {
  font-size: 16px;
  margin-bottom: 20px;
  .assessment-label {
    float: left;
  }
  .assessment-content-right {
    overflow: hidden;
  }
}

.td-red {
  color: red;
}

.td-yellow {
  color: #f3af1c;
}

.td-green {
  color: green;
}

.td-blue {
  color: #20a0ff;
}

</style>
