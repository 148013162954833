<template>
  <div class="overview-container">
    <div class="graph-header">
      <slot></slot>
    </div>
    <div class="graph-headings">
      <h2 class="graph-h2">血糖数据对比报告</h2>
      <h3 class="graph-h3">Comparative Report of Blood Glucose Data</h3>
    </div>
    <div class="graph-body">
      <div class="chart" ref="chartAll"></div>
      <Row>
        <Col span="12"><div class="chart" ref="chartAmount"></div></Col>
        <Col span="12"><div class="chart" ref="chartPercent"></div></Col>
      </Row>
    </div>
    <div class="graph-body">
      <h3 class="assessment-heading">监测结果分析：</h3>
      <p class="assessment-title red">第一部分：</p>
      <p class="assessment-content">1. {{sugar.str1}}</p>
      <hr class="divide-line--black">
      <p class="assessment-title red">第二部分：</p>
      <p class="assessment-content">2. {{sugarChart.sugar_str2}}</p>
      <hr class="divide-line--black">
      <p class="assessment-title red">第三部分：</p>
      <p class="assessment-content">3. {{sugarChart.sugar_str3}}</p>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
import echarts from 'echarts';

export default {
	props: {
		sugar: {
			type: Object,
		},
		sugarChart: {
			type: Object,
		},
	},
	data() {
		return {
			chartAll: null,
			chartAmount: null,
			chartPercent: null,
		};
	},
	mounted() {
		this.chartAll = echarts.init(this.$refs.chartAll);
		this.chartAmount = echarts.init(this.$refs.chartAmount);
		this.chartPercent = echarts.init(this.$refs.chartPercent);

		const monthLength = this.sugar.chart1[0].length - 1;
		const series = [];
		for (let i = 0; i < monthLength; i++) {
			series.push({ type: 'bar' });
		}
		const chartAllOpt = {
			legend: {},
			tooltip: {
				trigger: 'axis',
			},
			dataset: {
				source: this.sugar.chart1,
			},
			color: ['#5a9bd5', '#ed7d31', '#a5a5a5'],
			xAxis: {
				type: 'category',
				axisPointer: {
					type: 'shadow',
				},
			},
			yAxis: {
				min: function (value) {
					return value.min > 2 ? Math.ceil(value.min - 2) : 0;
				},
			},
			series: series,
		};

		const chartAmountOpt = {
			title: {
				text: '测量次数',
				textStyle: {
					fontWeight: 'bold',
					fontSize: 18,
				},
				left: 'center',
			},
			legend: {
				bottom: 0,
			},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow',
				},
			},
			dataset: {
				source: this.sugarChart.sugar_count,
			},
			color: ['#5a9bd5', '#ed7d31', '#a5a5a5', '#0aaf9f', '#e89589'],
			stack: 'all',
			xAxis: {
				type: 'category',
			},
			yAxis: {
				name: '次数',
			},
			series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }],
		};

		// 计算百分比
		// const dataSource = this.sugarChart.sugar_abnormal.map((arr, index) => {
		//   if (index !== 0) {
		//     // 计算数值之和 当是第一项时返回 0
		//     const total = arr.reduce((pre, cur, index) => {
		//       if (index !== 0) {
		//         return pre + cur
		//       } else {
		//         return 0
		//       }
		//     }, arr[1])
		//     // 计算每项数据占比 当是第一项时返回本身
		//     return arr.map((item, index) => {
		//       if (index !== 0) {
		//         return (item / total * 100).toFixed(2)
		//       }
		//       return item
		//     })
		//   }
		//   return arr
		// })
		const chartPercentOpt = {
			title: {
				text: '异常百分比',
				textStyle: {
					fontWeight: 'bold',
					fontSize: 18,
				},
				left: 'center',
			},
			legend: {
				bottom: 0,
			},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow',
				},
				// formatter: function(params, ticket) {
				//   return params[0].axisValue + '<br>' + params.map((item, index) => {
				//     return item.marker + item.seriesName + ': ' + item.value[index + 1] + '%<br>'
				//   }).join('')
				// }
			},
			grid: {
				x: 55,
			},
			dataset: {
				source: this.sugarChart.sugar_abnormal,
			},
			color: ['#5a9bd5', '#ed7d31', '#a5a5a5', '#0aaf9f', '#e89589'],
			stack: 'all',
			xAxis: {
				type: 'value',
				max: 100,
			},
			yAxis: {
				type: 'category',
			},
			series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }],
		};
		this.chartAll.setOption(chartAllOpt);
		this.chartAmount.setOption(chartAmountOpt);
		this.chartPercent.setOption(chartPercentOpt);
	},
	methods: {},
};
</script>

<style lang="less">
</style>
