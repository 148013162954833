<template>
  <div>
    <div class="search-box">
      <h2 class="search-title border-bottom">按条件搜索</h2>
      <Form class="search-form"
            :label-width="85"
           >
        <Form-item label="选择对象">
          <Row v-if="!branchShow">
            <Col span="5">
            <span class="sub-label">省份：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.pid"
                      placeholder="请选择..."
                      @on-change="changePro">
                <Option :value="v.id"
                        v-for="v in proList"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">城市：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.cid"
                      placeholder="请选择..."
                      @on-change="changeCity">
                <Option :value="v.id"
                        v-for="v in cityList"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">门店：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.sid"
                      placeholder="请选择..."
                      @on-change="changeStore">
                <Option :value="v.id"
                        v-for="v in storeList"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">会员：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="uid"
                      placeholder="请选择..."
                      filterable>
                <Option :value="v.id"
                        v-for="v in userList"
                        :key="v.id">{{v.true_name}}</Option>
              </Select>
            </div>
            </Col>
          </Row>
        <Row v-else>
            <Col span="5">
              <span class="sub-label">省份：</span>
                <div class="sub-content">
                  <Input disabled v-model="proName"></Input>
                </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">城市：</span>
            <div class="sub-content">
              <Input disabled v-model="cityName"></Input>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">门店：</span>
            <div class="sub-content">
              <Input disabled v-model="sName"></Input>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">会员：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="uid"
                      placeholder="请选择..."
                      filterable>
                <Option :value="v.id"
                        v-for="v in userList"
                        :key="v.id">{{v.true_name}}</Option>
              </Select>
            </div>
            </Col>
          </Row>
        </Form-item>
        <Form-item label="对比项目" v-if="itemArr.length">
          <Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll">全选</Checkbox>
        </Form-item>
        <Form-item label="" v-if="itemArr.length">
          <CheckboxGroup v-model="comparisonProject" @on-change="checkAllGroupChange">
            <Checkbox :label="v" v-for="(v, i) in itemArr" :key="i"></Checkbox>
          </CheckboxGroup>
        </Form-item>
        <Form-item label="对比时间">
          <CheckboxGroup
            v-model="comparisonTime"
            @on-change="handleCheckboxChange">
            <p>
              参考： <Checkbox label="首月"></Checkbox>
              <DatePicker type="month"
                style="width:100px;"
                v-model="customDatePicker[0].time"
                placeholder="自定义"
                :options="options"
                v-if="referenceTime"></DatePicker>
              <Icon type="md-add"
                class="btn-add"
                size="16"
                @click.native="addCustomTime(0)"></Icon>  
            </p>
            <p>
              对比： <Checkbox label="上月"></Checkbox>
              <DatePicker type="month"
                style="width:100px;"
                v-model="customDatePicker[1].time"
                placeholder="自定义"
                :options="options"
                v-if="compareTime"></DatePicker>
              <Icon type="md-add"
                class="btn-add"
                size="16"
                @click.native="addCustomTime(1)"></Icon>  
            </p>
            <p>
              评价： <Checkbox label="本月"></Checkbox>
              <DatePicker type="month"
                style="width:100px;"
                v-model="customDatePicker[2].time"
                placeholder="自定义"
                :options="options"
                v-if="commentTime"></DatePicker>
              <Icon type="md-add"
                class="btn-add"
                size="16"
                @click.native="addCustomTime(2)"></Icon>  
            </p>
          </CheckboxGroup>
        </Form-item>
        <Form-item label="">
          <Button @click="generateReport" style="margin-right: 4px;">生成报告</Button>
          <Button @click="saveAsPDF">存为PDF</Button>
          <Button @click="saveAsPng" style="margin-left: 4px;">存为PNG</Button>
        </Form-item>
      </Form>
    </div>
    <!-- <div class="content-box mb20">
      <h2 class="content-title border-bottom">人体成分数据</h2>
      <div class="content-body">
        <Table
          :columns="reportColumns"
          :data="reportList"
          :height="220"
        ></Table>
        <div>
          <span class="records">共 {{ reportTotal }} 条记录</span>
        </div>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page
              :total="reportTotal"
              :page-size="reportData.size"
              @on-change="changeReportPage"></Page>
          </div>
        </div>
      </div>
    </div> -->
    <div class="content-box">
      <h2 class="content-title border-bottom">数据结果</h2>
      <div class="breadcrumbs-bar">
        <span :class="['breadcrumb', {'active': i === clickIndex}]" v-for="(v, i) in cacheProject" :key="i" @click="clickBreadcrumb(i)">{{v}}</span>
      </div>
      <div class="graph-content" ref="pdfContent">
        <div style="padding:20px 0;text-align:center;" v-if="cacheProject.length === 0">暂无数据</div>
        <comparison-report class="mb50" v-if="cacheProject.includes('封面')" :report="report">
          <div style="font-size:22px">报告日期：{{putOn}}</div>
          <div><img class="header-img" src="@/assets/img/logo-new.png"></div>
        </comparison-report>
        <comparison-overview class="mb50" v-if="cacheProject.includes('信息汇总')" :overview="overview">
          <div style="font-size:22px">报告日期：{{putOn}}</div>
          <div><img class="header-img" src="@/assets/img/logo-new.png"></div>
        </comparison-overview>
        <comparison-blood class="mb50" v-if="cacheProject.includes('血压')" :blood="blood">
          <div style="font-size:22px">报告日期：{{putOn}}</div>
          <div><img class="header-img" src="@/assets/img/logo-new.png"></div>
        </comparison-blood>
        <comparison-sugar class="mb50" v-if="cacheProject.includes('血糖')" :sugar="sugar" :sugarChart="overview">
          <div style="font-size:22px">报告日期：{{putOn}}</div>
          <div><img class="header-img" src="@/assets/img/logo-new.png"></div>
        </comparison-sugar>
        <comparison-body class="mb50" v-if="cacheProject.includes('人体成分')" :body="body">
          <div style="font-size:22px">报告日期：{{putOn}}</div>
          <div><img class="header-img" src="@/assets/img/logo-new.png"></div>
        </comparison-body>
        <comparison-energy class="mb50" v-if="cacheProject.includes('机能')" :energy="energy" ref="energy">
          <div style="font-size:22px">报告日期：{{putOn}}</div>
          <div><img class="header-img" src="@/assets/img/logo-new.png"></div>
        </comparison-energy>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ComparisonReport from './comparison-report';
import ComparisonOverview from './comparison-overview';
import ComparisonBlood from './comparison-blood';
import ComparisonSugar from './comparison-sugar';
import ComparisonBody from './comparison-body';
import ComparisonEnergy from './comparison-energy';
import qualityService from '@/services/qualityService';
import comparisonService from '@/services/comparisonService';
import memberService from '@/services/memberService';
import dayjs from 'dayjs';

export default {
	components: {
		ComparisonReport,
		ComparisonOverview,
		ComparisonBlood,
		ComparisonSugar,
		ComparisonBody,
		ComparisonEnergy,
	},
	data() {
		return {
			options: {
				disabledDate(date) {
					return date && date.valueOf() > Date.now();
				},
			},
			searchData: {
				page: 1,
				size: 1000,
				pid: '',
				cid: '',
				sid: '',
				simple_query: 1,
			},
			branchShow: false,
			branch_id: [],
			power: [],
			proName: '',
			cityName: '',
			sName: '',
			storeList: [],
			proList: [],
			cityList: [],
			userList: [],
			uid: '',
			itemArr: ['封面', '信息汇总', '血压', '血糖', '人体成分', '机能'],
			putOn: '',
			comparisonProject: [],
			cacheProject: [],
			comparisonTime: [],
			customDatePicker: [{ time: '' }, { time: '' }, { time: '' }],
			referenceTime: false,
			compareTime: false,
			commentTime: false,
			report: {},
			overview: {},
			clickIndex: 0,
			scrollFunc: null,
			scrollPage: null,
			indeterminate: false,
			checkAll: false,
			userInfo: {},
			reportColumns: [
				{ title: '日期', key: 'test_date', align: 'center' },
				{ title: '采集时间', key: 'test_time', align: 'center' },
				{ title: '采集方式', key: 'type', align: 'center' },
				{ title: '体重', key: 'weight', align: 'center' },
				{ title: '体格指数', key: 'build', align: 'center' },
				{ title: '体脂肪率', key: 'body_fat', align: 'center' },
				{ title: '内脂指数', key: 'lactone', align: 'center' },
				{ title: '体水分率', key: 'body_water', align: 'center' },
				{ title: '骨骼肌率', key: 'musculi_skeleti', align: 'center' },
				{ title: '骨矿含量', key: 'bmc', align: 'center' },
				{ title: '基础代谢', key: 'basic', align: 'center' },
			],
			reportList: [],
			reportTotal: 0,
			reportData: {
				page: 1,
				size: 10,
				index: 1,
			},
		};
	},
	computed: {
		customTime() {
			return this.customDatePicker.reduce((result, cur) => {
				const month =
					cur.time &&
					(cur.time.getMonth() + 1 < 10
						? '0' + (cur.time.getMonth() + 1)
						: cur.time.getMonth() + 1);
				return cur.time ? result.concat(`${cur.time.getFullYear()}-${month}`) : result;
			}, []);
		},
	},
	created() {
		let list = JSON.parse(localStorage.getItem('userInfo'));
		qualityService.getQualitySelect().then((data) => {
			this.power = list.user_info.power;
			if (this.power.indexOf('会员管理--查看所属店面会员信息权限') === -1) {
				this.branchShow = false;
			} else {
				if (
					this.power.indexOf('会员管理--查看全国会员信息权限') === -1 &&
					this.power.indexOf('会员管理--查看所属区域会员信息权限') === -1
				) {
					this.branchShow = true;
					this.branch_id = list.user_info.branch;
					this.searchData.sid = this.branch_id[2];
					this.searchData.cid = this.branch_id[1];
					this.searchData.pid = this.branch_id[0];
					this.proName = list.user_info.pro_name;
					this.cityName = list.user_info.city_name;
					this.sName = list.user_info.branch_name;
					this.changeStore();
				}
			}
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	mounted() {
		this.scrollPage = function (offsetArr, self) {
			const scrollTop = document.documentElement.scrollTop;
			switch (true) {
				case scrollTop < offsetArr[0]:
					self.clickIndex = 0;
					break;
				case scrollTop < offsetArr[1]:
					self.clickIndex = 1;
					break;
				case scrollTop < offsetArr[2]:
					self.clickIndex = 2;
					break;
				case scrollTop < offsetArr[3]:
					self.clickIndex = 3;
					break;
				case scrollTop < offsetArr[4]:
					self.clickIndex = 4;
					break;
				case scrollTop < offsetArr[5]:
					self.clickIndex = 5;
					break;
				case scrollTop < offsetArr[6]:
					self.clickIndex = 6;
					break;
				case scrollTop < offsetArr[7]:
					self.clickIndex = 7;
					break;
				default:
					self.clickIndex = offsetArr.length;
			}
		};
	},
	destroyed() {
		window.removeEventListener('scroll', this.scrollFunc);
	},
	methods: {
		// 添加自定义时间
		addCustomTime(flag) {
			if (flag === 0) {
				if (!this.comparisonTime.includes('首月')) {
					this.referenceTime = true;
				} else {
					this.$Message.warning('已勾选参考时间');
				}
			} else if (flag === 1) {
				if (!this.comparisonTime.includes('上月')) {
					this.compareTime = true;
				} else {
					this.$Message.warning('已勾选对比时间');
				}
			} else if (flag === 2) {
				if (!this.comparisonTime.includes('本月')) {
					this.commentTime = true;
				} else {
					this.$Message.warning('已勾选评价时间');
				}
			}
		},
		// checkbox change 事件
		handleCheckboxChange() {
			const checkedLength = this.comparisonTime.length + this.customTime.length;
			if (checkedLength > 3) {
				this.comparisonTime.pop();
				this.$Message.warning('最多添加三个比对时间');
			}
		},
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		// 根据门店选择用户
		changeStore() {
			if (this.searchData.sid) {
				comparisonService.getUserList(this.searchData).then((data) => {
					this.userList = data.list;
				});
			} else {
				this.userList = [];
			}
		},
		// 点击 breadcrumb
		clickBreadcrumb(index) {
			this.clickIndex = index;
			const doc = document.documentElement;
			let scrollTop = doc.scrollTop;
			const anchor = document.querySelectorAll('.mb50')[index].offsetTop - 100;
			const scroll = function () {
				scrollTop = scrollTop + (anchor - scrollTop) / 4;
				if (Math.abs(scrollTop - anchor) < 1) {
					doc.scrollTop = anchor;
					return;
				}
				doc.scrollTop = scrollTop;
				requestAnimationFrame(scroll);
			};
			scroll();
		},
		// 生成报告
		generateReport() {
			if (!this.uid) {
				this.$Message.warning('请选择会员');
				return;
			}
			if (this.comparisonProject.length === 0) {
				this.$Message.warning('请选择项目');
				return;
			}
			const checkedLength = this.comparisonTime.length + this.customTime.length;
			if (checkedLength < 2) {
				this.$Message.warning('最少选择两个时间进行数据比对');
				return;
			}

			this.getReportList();

			// 销毁滚动事件
			window.removeEventListener('scroll', this.scrollFunc);
			this.cacheProject = [];
			const params = {
				member_id: this.uid,
				compare: JSON.stringify(this.comparisonTime),
				custom: JSON.stringify(this.customTime),
				ny: this.reportTime,
			};
			comparisonService.getResults(params).then((data) => {
				this.itemArr.forEach((item) => {
					if (this.comparisonProject.includes(item)) {
						this.cacheProject.push(item);
					}
				});

				const commentTime = new Date().toLocaleDateString('zh-CN', {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
				});
				const compareTime = this.comparisonTime.concat(this.customTime).join(',');
				this.putOn = dayjs().format('YYYY/MM/DD');
				this.report = { ...data.overview, commentTime, compareTime };
				this.blood = data.blood;
				this.body = data.body;
				this.sugar = data.sugar;
				this.overview = data.statics;
				this.energy = data.jineng;
				this.userInfo = { createTime: dayjs().format('YYYYMMDD') };

				// 延时加载滚动函数，此处 nextTick() 效果不完美
				setTimeout(() => {
					const self = this;
					const contentWrapper = document.querySelectorAll('.mb50');
					const offsetArr = Array.from(contentWrapper).map((item) => item.offsetTop - 120);
					offsetArr.shift();
					if (offsetArr.length > 0) {
						this.scrollFunc = function () {
							self.scrollPage(offsetArr, self);
						};
						window.addEventListener('scroll', this.scrollFunc);
					}
				}, 1000);
			});
		},
		changeReportPage(page) {
			this.reportData.page = page;
			this.getReportList();
		},
		getReportList() {
			memberService
				.bodyQuery({
					...this.reportData,
					member_id: this.uid,
				})
				.then((data) => {
					this.reportTotal = data.data_show.row_size;
					this.reportList = data.data_show.list.map((item) => {
						const arr = item.create_time.split(' ');
						item.test_date = arr[0];
						item.test_time = arr[1];
						item.musculi_skeleti = item.musculi_skeleti === 0 ? '' : `${item.musculi_skeleti}%`;
						item.weight = item.weight === 0 ? '' : `${item.weight}kg`;
						item.bmc = item.bmc === 0 ? '' : `${item.bmc}kg`;
						item.body_fat = item.body_fat === 0 ? '' : `${item.body_fat}%`;
						item.build = item.build === 0 ? '' : `${item.build}`;
						item.body_water = item.body_water === 0 ? '' : `${item.body_water}%`;
						item.basic = item.basic === 0 ? '' : `${item.basic}kcal`;
						item.lactone = item.lactone === 0 ? '' : `${item.lactone}`;
						if (item.type === '0') {
							item.type = '手动';
						} else if (item.type === '1') {
							item.type = 'APP';
						} else {
							item.type = '';
						}
						return item;
					});
				});
		},
		saveAsPDF() {
			const loading = this.$loading({
				lock: true,
				text: '正在存储中，请稍等',
				spinner: 'el-icon-loading',
			});
			const printTarget = document.querySelectorAll('.overview-container');
			if (printTarget.length > 0) {
				const self = this;
				const pdf = new JsPDF({ compress: true, unit: 'pt' });
				Promise.all(
					Array.from(printTarget).map((item) =>
						html2canvas(item, {
							scale: 1,
							scrollX: 0,
							scrollY: -window.scrollY,
						}),
					),
				).then((canvasList) => {
					for (let i = 0; i < canvasList.length; i++) {
						const canvas = canvasList[i];
						const contentWidth = canvas.width;
						const contentHeight = canvas.height;

						const imgWidth = 555.28;
						const imgHeight = (imgWidth / contentWidth) * contentHeight;

						const pageData = canvas.toDataURL('image/png', 1.0);

						if (i !== 0) pdf.addPage();
						pdf.addImage(pageData, 'png', 20, 0, imgWidth, imgHeight);

						if (i === printTarget.length - 1) {
							const fileName = `${self.report.name}${self.userInfo.createTime}生理对比报告.pdf`;
							const formData = new FormData();
							formData.append('photo', pdf.output('blob'));
							formData.append('name', fileName);
							memberService.uploadReport(formData).then((data) => {
								memberService
									.addReport({
										member_id: self.uid,
										type: 4,
										bgzl: 46,
										check_date: dayjs().format('YYYY-MM-DD'),
										photo_id: data.id,
										bgms: '出具报告自动上传',
									})
									.then(() => {
										self.$Message.success('转存成功');
									})
									.catch(() => {
										self.$Message.success('转存失败，请手动上传');
									});
							});

							pdf.save(fileName);
							self.$nextTick(() => {
								loading.close();
							});
						}
					}
				});
			} else {
				this.$Message.warning('无法生成PDF，请检查数据是否存在');
			}
		},
		saveAsPng() {
			const printTarget = document.querySelectorAll('.overview-container');
			if (printTarget.length > 0) {
				const self = this;
				html2canvas(this.$refs.pdfContent).then(function (canvas) {
					const imgData = canvas.toDataURL('image/png', 1.0);
					const a = document.createElement('a');
					a.href = imgData;
					a.style.display = 'none';
					a.setAttribute(
						'download',
						`${self.report.name}${self.userInfo.createTime}生理对比报告.png`,
					);
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				});
			} else {
				this.$Message.warning('无法生成PNG，请检查数据是否存在');
			}
		},
		handleCheckAll() {
			if (this.indeterminate) {
				this.checkAll = false;
			} else {
				this.checkAll = !this.checkAll;
			}
			this.indeterminate = false;

			if (this.checkAll) {
				this.comparisonProject = this.itemArr;
			} else {
				this.comparisonProject = [];
			}
		},
		checkAllGroupChange(data) {
			if (data.length === this.itemArr.length) {
				this.indeterminate = false;
				this.checkAll = true;
			} else if (data.length > 0) {
				this.indeterminate = true;
				this.checkAll = false;
			} else {
				this.indeterminate = false;
				this.checkAll = false;
			}
		},
		handleClickSubTab(i) {
			this.selectedTab = i;
			this.itemArr = this.subTabs[i];
			this.comparisonProject = [];
			this.indeterminate = false;
			this.checkAll = false;
		},
	},
};
</script>

<style lang="less" scoped>
.sub-label {
  vertical-align: middle;
  float: left;
}

.sub-content {
  margin-left: 50px;
}

.btn-add {
  cursor: pointer;
  margin-left: 10px;
}

.btn-add:hover {
  color: #fdad00;
}

.content-box {
  background-color: #fff;
  &:last-child {
    padding-bottom: 20px;
  }
}

.breadcrumbs-bar {
  display: flex;
  border-bottom: #eee solid 1px;
  position: sticky;
  top: 23px;
  background-color: #fff;
  z-index: 10;
  .breadcrumb {
    flex: 1;
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      color: #fdad00;
    }
    &.active {
      color: #fdad00;
    }
  }
}

.content-title {
  height: 39px;
  line-height: 39px;
  padding-left: 10px;
  background-color: #fbfbfb;
}

.graph-content {
  padding: 0 30px;
}

.mb50 {
  margin-bottom: 50px;
}

.content-panel {
  display: flex;
  justify-content: center;
  .sub-tab {
    padding: 22px 15px;
    &-item {
      height: 32px;
      line-height: 32px;
      width: 140px;
      text-align: center;
      background-color: #fff;
      margin-bottom: 20px;
      border-radius: 4px;
      box-shadow: 0 2px 5px #bbb;
      cursor: pointer;
      &.active {
        background-color: #fdad00;
        color: #fff;
        font-weight: 700;
      }
    }
  }
}

.month-picker {
  display: inline-block;
  vertical-align: middle;
  &-item {
    border-radius: 4px;
    border: #fdad00 solid 1px;
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    display: inline-block;
    margin-right: 4px;
    &.active {
      background-color: #fdad00;
      color: #fff;
    }
  }
}

.header-img {
  width: 220px;
  margin-right: 5px;
}
</style>
