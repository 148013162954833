<template>
  <div class="overview-container">
    <div class="graph-header">
      <slot></slot>
    </div>
    <div class="graph-headings">
      <h2 class="graph-h2">生理与行为对比报告</h2>
      <h3 class="graph-h3">Comparative Report on Physiology and Behavior</h3>
    </div>
    <div class="graph-body">
      <div class="flex-wrapper">
        <div class="flex-left">
          <p class="title-zh">基本信息</p>
          <p class="title-en">Information</p>
          <img src="@/assets/img/arrow-right.png" alt="arrow" class="arrow-right">
        </div>
        <div class="flex-right">
          <Row class="row-item">
            <Col span="8">
              <span class="item-label">姓名：</span>
              <span class="item-content">{{report.name}}</span>
            </Col>
            <Col span="8">
              <span class="item-label">性别：</span>
              <span class="item-content">{{report.sex}}</span>
            </Col>
            <Col span="8">
              <span class="item-label">年龄：</span>
              <span class="item-content">{{report.age}}</span>
            </Col>
          </Row>
          <Row class="row-item">
            <Col span="12">
              <span class="item-label">门店：</span>
              <span class="item-content">{{report.branch}}</span>
            </Col>
            <Col span="12">
              <span class="item-label">电话：</span>
              <span class="item-content">{{report.mobile}}</span>
            </Col>
          </Row>
          <Row class="row-item">
            <Col span="12">
              <span class="item-label">评价时间：</span>
              <span class="item-content">{{report.commentTime}}</span>
            </Col>
            <Col span="12">
              <span class="item-label">对比时间：</span>
              <span class="item-content">{{report.compareTime}}</span>
            </Col>
          </Row>
        </div>
      </div>
      <hr class="divide-line">
      <div class="body-wrapper">
        <p class="row-title">首页 <span>Home page</span><img src="@/assets/img/arrow-down.png" alt="arrow"></p>
        <div class="row-content">
          <div class="tag-content">首页</div>
          <div class="tag-content">生理汇总</div>
          <div class="tag-content">行为汇总</div>
          <div class="tag-content">月度评价</div>
        </div>
        <p class="row-title">可视化分析 <span>Visual analysis</span><img src="@/assets/img/arrow-down.png" alt="arrow"></p>
        <div class="row-content">
          <div class="tag-content">血压</div>
          <div class="tag-content">血糖</div>
          <div class="tag-content">人体成分</div>
          <div class="tag-content">机能</div>
        </div>
        <img src="@/assets/img/shutterstock.jpg" width="100%">
      </div>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
export default {
	props: {
		report: {
			type: Object,
		},
	},
	data() {
		return {};
	},
	methods: {},
};
</script>

<style lang="less" scoped>
.flex-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-left {
  position: relative;
  text-align: center;
  width: 200px;
  font-weight: 700;
  .title-zh {
    font-size: 24px;
    color: #4370c1;
  }
  .title-en {
    font-size: 18px;
  }
  .arrow-right {
    position: absolute;
    right: 20px;
    top: 15px;
  }
}

.flex-right {
  flex: 1;
}

.row-item {
  margin: 25px 0;
  font-size: 22px;
  font-weight: 700;
  .item-label {
    width: 120px;
    text-align: right;
    display: inline-block;
  }
  .item-content {
    display: inline-block;
    width: 50%;
    border-bottom: 1px solid #000;
  }
}

.body-wrapper {
  width: 80%;
  text-align: center;
  margin: 0 auto;
  .row-title {
    color: #4370c1;
    font-size: 24px;
    font-weight: 700;
    span {
      font-size: 16px;
      color: #000;
    }
    img {
      margin-left: 5px;
      vertical-align: baseline;
    }
  }
  .row-content {
    display: flex;
    justify-content: space-around;
    margin: 30px 0 50px;
    .tag-content {
      width: 18%;
      background-color: #4370c1;
      padding: 5px 0;
      color: #fff;
      font-size: 22px;
      font-weight: 700;
      border-radius: 6px;
    }
  }
}
</style>
